import React from 'react'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'
import styled from 'styled-components'

import SEO from '../components/SEO'
import HeroBlock from '../components/HeroBlock'
import FullWidthWrapper from '../components/FullWidthWrapper'
import ForegroundLayout from '../components/ForegroundLayout'
import Overlay from '../components/Overlay'
import WordArt from '../components/WordArt'
import ContentBlock from '../components/ContentBlock'
import AngledDivider from '../components/AngledDivider'
import BigHeading from '../components/BigHeading'
import FlexBox from '../components/FlexBox'
import ActionLink from '../components/ActionLink'
import ContactSection from '../components/ContactSection'

import ArrowOrange from '../graphics/ne-arrow.svg'

const Work = ({ data }) => (
  <React.Fragment>
    <SEO title="Our Work" />
    <HeroBlock>
      <HeroHeading />
    </HeroBlock>
    <AngledDivider color="#1b1b1b" position="top" />
    <StyledList>
      {data.work.edges.map(edge => (
        <li key={edge.node.id}>
          <AngledDivider color="#1b1b1b" position="bottom" />
          <FullWidthWrapper key={edge.node.id} height="80vh">
            <Image
              fluid={edge.node.fields.imageFile.childImageSharp.fluid}
              alt=""
              css={`
                height: 100%;
              `}
            />
            <Overlay alpha={0.4} />
            <ForegroundLayout
              css={`
                top: -100%;
              `}
            >
              <FlexBox
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                itemSpacing="5vh"
              >
                <BigHeading
                  css={`
                    text-align: center;
                  `}
                >
                  {edge.node.frontmatter.title}
                </BigHeading>
                <ActionLink to={edge.node.frontmatter.path}>
                  See Case Study
                  <ArrowOrange
                    css={`
                      margin-left: 1rem;
                    `}
                  />
                </ActionLink>
              </FlexBox>
            </ForegroundLayout>
          </FullWidthWrapper>
          <AngledDivider color="#1b1b1b" position="top" />
        </li>
      ))}
    </StyledList>
    <ContentBlock backgroundColor="#1b1b1b" />
    <AngledDivider color="#1b1b1b" position="bottom" />
    <ContactSection />
  </React.Fragment>
)

const HeroHeading = () => (
  <WordArt.Wrapper>
    <WordArt.Line color="#f93616" fontSize="1.3em">
      The Impact
    </WordArt.Line>
    <WordArt.Line color="#fff" fontSize="1.2em">
      We've Made
    </WordArt.Line>
  </WordArt.Wrapper>
)

const StyledList = styled('ul')`
  list-style: none;
  padding: 0;
  margin: 0;
`

export const query = graphql`
  query {
    work: allMdx(sort: { fields: [frontmatter___order], order: ASC }) {
      edges {
        node {
          id
          frontmatter {
            path
            title
          }
          fields {
            imageFile {
              childImageSharp {
                fluid(maxWidth: 1800, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Work
